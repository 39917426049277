import React from 'react';
import logo from '../img/2023.jpg'
const Header = () => {
    return (
         <header>
            <img src={logo} alt='logo'/>
             <h2>ООО «КАЧЕСТВЕННЫЕ РЕШЕНИЯ»</h2>
        </header>
    )
}

export default Header;
